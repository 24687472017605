.spinner-wrapper {
    /* position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    background: #000;
    opacity: 0.5; */
    /* background: #ff0000; */
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    width: 100%;
    margin-top:10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.billToUser {
    margin-top:10px;
}